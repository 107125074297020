import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, styled, Typography } from "@mui/material";
import {
  ISubmenuProp,
  StyledLinkProps,
  IStyledLabelProp,
} from "shared/interfaces/submenuInterface";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
const itemsToExcludeOnDom = [
  "isActive",
  "mobile",
  "hasChildren",
  "superAdmin",
  "sideMenu",
  "activeColors",
];
const SidebarLink = styled(Link, {
  shouldForwardProp: (prop) => !itemsToExcludeOnDom.includes(prop as string),
})<StyledLinkProps>(
  ({ isActive, theme, mobile, hasChildren, superAdmin, color, sideMenu, activeColors }) => ({
    display: "flex",
    color:
      isActive && !hasChildren
        ? superAdmin
          ? theme.palette.primary.main
          : color
          ? color
          : theme.palette.common.white
        : superAdmin
        ? theme.palette.text.primary
        : theme.palette.common.white,

    background:
      isActive && !hasChildren
        ? superAdmin
          ? theme.palette.background.default
          : activeColors?.bg || "rgba(255, 255, 255, 0.3"
        : "transparent",
    borderLeftColor: isActive
      ? superAdmin
        ? theme.palette.primary.main
        : activeColors?.borderLeft || color
        ? color
        : theme.palette.common.white
      : superAdmin
      ? theme.palette.text.primary
      : // @ts-ignore
        theme?.palette?.tertiary?.main,
    borderLeftWidth: isActive ? 0.2 : 0,
    borderLeftStyle: isActive ? "solid" : "none",
    justifyContent: "space-between",
    alignItems: "center",
    listStyle: "none",
    textDecoration: "none",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingLeft: sideMenu ? 0 : mobile ? "0.2rem" : "2rem",
    width: "100%",
    "&:hover": {
      background:
        isActive && !hasChildren
          ? superAdmin
            ? theme.palette.background.default
            : activeColors?.bg || "rgba(255, 255, 255, 0.3)"
          : "rgba(0, 0, 0, 0.04)",
      cursor: "pointer",
    },
  })
);

const SidebarLabel = styled("p")<IStyledLabelProp>(({ sideMenu }: any) => ({
  marginLeft: sideMenu ? 0 : 16,
  fontSize: sideMenu ? 9 : 14,
}));

const DropdownLink = styled(Link, {
  shouldForwardProp: (prop) => !itemsToExcludeOnDom.includes(prop as string),
})<StyledLinkProps>(({ isActive, theme, mobile, superAdmin, sideMenu }) => ({
  display: "flex",
  color: superAdmin ? theme.palette.primary.main : theme.palette.common.white,
  borderLeftColor: isActive
    ? superAdmin
      ? theme.palette.primary.main
      : theme.palette.common.white
    : superAdmin
    ? theme.palette.text.primary
    : // @ts-ignore
      theme.palette.tertiary.main,
  background: isActive
    ? superAdmin
      ? theme.palette.background.default
      : "rgba(255, 255, 255, 0.3)"
    : "transparent",
  //borderLeftWidth: isActive ? 2 : 0,
  borderLeftStyle: isActive ? "solid" : "none",
  alignItems: "center",
  listStyle: "none",
  textDecoration: "none",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  paddingLeft: sideMenu ? 0 : mobile ? "0.2rem" : "1rem",

  "&:hover": {
    background: isActive
      ? superAdmin
        ? theme.palette.background.default
        : "rgba(255, 255, 255, 0.3)"
      : "rgba(0, 0, 0, 0.04)",
    cursor: "pointer",
  },
}));

const flexStyle = {
  display: "flex",
  alignItems: "center",
};
const stackStyle = { textAlign: "center", margin: "8px 0px" };

const SubMenu = ({
  item,
  mobile,
  superAdmin,
  stackMenu,
  color,
  activeColors,
  sx,
  defaultColor,
}: ISubmenuProp) => {
  const { userDetails } = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const isNavActive = !!(
    item.url(userDetails) &&
    (location.pathname.includes(item.url(userDetails)) ||
      `${location.pathname}${location.search}`.includes(item.url(userDetails)))
  );
  const navHasChildren = Boolean(item?.subNav && item?.subNav?.length > 0);
  return (
    <>
      <SidebarLink
        sideMenu={stackMenu}
        to={navHasChildren ? "#" : item.url(userDetails)}
        onClick={item.onClick ? () => item.onClick?.(navigate) : item.subNav && showSubnav}
        hasChildren={navHasChildren}
        mobile={mobile || false}
        isActive={
          !!(
            item.url(userDetails) &&
            (location.pathname.includes(item.url(userDetails)) ||
              `${location.pathname}${location.search}`.includes(item.url(userDetails)))
          )
        }
        superAdmin={superAdmin || false}
        color={color}
        activeColors={activeColors}
      >
        <>
          <Box
            display="flex"
            //alignItems={!stackMenu ? "center" : "flex-start"}
            alignItems="center"
            justifyContent={"center"}
            //justifyContent={stackMenu ? "center" : "flex-start"}
            flexDirection={stackMenu ? "column" : "row"}
            // gap={!stackMenu ? 2 : ""}
            width="100%"
            sx={sx}

            // flexWrap="wrap"
          >
            {/* {item?.icon(!!(item.url && location.pathname.includes(item.url)))} */}
            <Box
              width={"20%"}
              sx={{
                color: (theme) =>
                  isNavActive && !navHasChildren
                    ? superAdmin
                      ? theme.palette.primary.main
                      : color
                      ? color
                      : theme.palette.common.white
                    : superAdmin
                    ? theme.palette.text.primary
                    : "black",
              }}
            >
              {item?.icon?.(superAdmin) ?? <div />}
            </Box>
            {stackMenu || (
              <Typography
                lineHeight="12px"
                component="span"
                variant="body2"
                width={"80%"}
                ml={-3}
                sx={{
                  overflowWrap: "anywhere !important",
                  color: (theme) =>
                    isNavActive && !navHasChildren
                      ? superAdmin
                        ? theme.palette.primary.main
                        : color
                        ? color
                        : theme.palette.common.white
                      : superAdmin
                      ? theme.palette.text.primary
                      : defaultColor || "white",
                }}
              >
                {item.name}
              </Typography>
            )}
            {stackMenu && (
              <Typography
                lineHeight="12px"
                component="span"
                variant="caption"
                color={color || "common.white"}
                textAlign="center"
                sx={{ overflowWrap: "anywhere !important" }}
              >
                {item.name}
              </Typography>
            )}
          </Box>

          <Box sx={stackMenu ? stackStyle : flexStyle}>
            {item.subNav && subnav ? (
              <KeyboardArrowUpIcon />
            ) : item.subNav ? (
              <KeyboardArrowDownIcon />
            ) : null}
          </Box>
        </>
      </SidebarLink>
      {subnav &&
        item?.subNav?.map((subnavItem) => (
          <DropdownLink
            to={subnavItem?.url(userDetails)}
            key={subnavItem?.url(userDetails)}
            mobile={mobile}
            isActive={
              !!(
                subnavItem.url(userDetails) &&
                location.pathname.includes(subnavItem.url(userDetails))
              )
            }
            superAdmin={superAdmin}
            sideMenu={stackMenu}
          >
            {/* {subnavItem?.icon(
              !!(subnavItem.url && location.pathname.includes(subnavItem.url))
            )} */}
            <span style={{ marginLeft: stackMenu ? 15 : 0 }}>
              {" "}
              {item?.icon?.(superAdmin) ?? <div />}
            </span>
            <SidebarLabel sideMenu={stackMenu}>{subnavItem?.name}</SidebarLabel>
          </DropdownLink>
        ))}
    </>
  );
};

export default SubMenu;
