export const TITLE = [
  {
    label: "Mr",
    value: "Mr",
  },
  {
    label: "Mrs",
    value: "Mrs",
  },
  {
    label: "Chief",
    value: "Chief",
  },
  {
    label: "Miss",
    value: "Miss",
  },
  {
    label: "Master",
    value: "Master",
  },
  {
    label: "Dr",
    value: "Dr",
  },
  {
    label: "Engr",
    value: "Engr",
  },
  {
    label: "Prof",
    value: "Prof",
  },
  {
    label: "Barr",
    value: "Barr",
  },
  {
    label: "Hon",
    value: "Hon",
  },
];

export const RECIPIENT_ROLE = [
  { key: "MDA_ACCOUNTING_OFFICER", value: "Permanent Secretary" },
  { key: "MDA_SUPERVISING_OFFICER", value: "Honorable commissioner" },
  { key: "MDA_REVIEWER", value: "Reviewer" },
  { key: "MDA_INITIATOR", value: "Initiator" },
];

export const Roles = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  IT_ADMIN: "IT_ADMIN",
  MDA_ADMIN: "MDA_ADMIN",
  BUDGET_ADMIN: "BUDGET_ADMIN",
  EMM_ADMIN: "EMM_ADMIN",
  GLOBAL_AUDITOR: "GLOBAL_AUDITOR",
  MDA_REVIEWER: "MDA_REVIEWER",
  MDA_INITIATOR: "MDA_INITIATOR",
  MDA_INTERNAL_AUDITOR: "MDA_INTERNAL_AUDITOR",
  MDA_AUTHORIZER: "MDA_AUTHORIZER",
  MDA_ACCOUNTING_OFFICER: "MDA_ACCOUNTING_OFFICER",
  MDA_SUPERVISING_OFFICER: "MDA_SUPERVISING_OFFICER",
  DIRECTOR_OF_EXPENDITURE: "DIRECTOR_OF_EXPENDITURE",
  WARRANT_INITIATOR: "WARRANT_INITIATOR",
  WARRANT_AUTHORISER: "WARRANT_AUTHORISER",
  WARRANT_REQUEST_REVIEWER: "WARRANT_REQUEST_REVIEWER",
  PAYMENT_REQUEST_PROCESSING_INITIATOR: "PAYMENT_REQUEST_PROCESSING_INITIATOR",
  CONTRACT_PAYMENT_REQUEST_PROCESSING_CHECKER: "CONTRACT_PAYMENT_REQUEST_PROCESSING_CHECKER",
  EXPENDITURE_REQUEST_REVIEWER: "EXPENDITURE_REQUEST_REVIEWER",
  EXPENDITURE_CLEARANCE_INITIATOR: "EXPENDITURE_CLEARANCE_INITIATOR",
  EXPENDITURE_CLEARANCE_AUTHORISER: "EXPENDITURE_CLEARANCE_AUTHORISER",
  PAYMENT_REQUEST_PROCESSING_REVIEWER: "PAYMENT_REQUEST_PROCESSING_REVIEWER",
  PAYMENT_REQUEST_PROCESSING_AUTHORISER: "PAYMENT_REQUEST_PROCESSING_AUTHORISER",
  SE_EXPENDITURE: "SE_EXPENDITURE",
  CASH_OFFICER: "CASH_OFFICER",
  GLO_INITIATOR: "GLO_INITIATOR",
  GLO_REVIEWER: "GLO_REVIEWER",
  FUNDS_MANAGER: "FUNDS_MANAGER",
  SE_FUND_RELEASE: "SE_FUND_RELEASE",
  SE_WARRANT: "SE_WARRANT",
  MDA_AUTHORISER: "MDA_AUTHORISER",
  WARRANT_REQUEST_AUTHORISER: "WARRANT_REQUEST_AUTHORISER",
  CONTRACT_PAYMENT_REQUEST_REVIEWER: "CONTRACT_PAYMENT_REQUEST_REVIEWER",
  TREASURY_CASH_OFFICER: "TREASURY_CASH_OFFICER",
  WARRANT_REQUEST_APPROVER: "WARRANT_REQUEST_APPROVER",
  RECONCILIATION_INITIATOR: "RECONCILIATION_INITIATOR",
  RECONCILIATION_AUTHORISER: "RECONCILIATION_AUTHORISER",
  MDA_MODERATOR: "MDA_MODERATOR",
  WARRANT_REQUEST_CHECKER: "WARRANT_REQUEST_CHECKER",
  EXPENDITURE_CLEARANCE_CHECKER: "EXPENDITURE_CLEARANCE_CHECKER",
  WARRANT_REQUEST_ENDORSER: "WARRANT_REQUEST_ENDORSER",
  EXPENDITURE_DIGITIZATION_INITIATOR: "EXPENDITURE_DIGITIZATION_INITIATOR",
  EXPENDITURE_DIGITIZATION_REVIEWER: "EXPENDITURE_DIGITIZATION_REVIEWER",
  EXPENDITURE_DIGITIZATION_AUTHORISER: "EXPENDITURE_DIGITIZATION_AUTHORISER",
  WARRANT_DIGITIZATION_INITIATOR: "WARRANT_DIGITIZATION_INITIATOR",
  WARRANT_DIGITIZATION_REVIEWER: "WARRANT_DIGITIZATION_REVIEWER",
  WARRANT_DIGITIZATION_AUTHORISER: "WARRANT_DIGITIZATION_AUTHORISER",
  WARRANT_GENERATION_INITIATOR: "WARRANT_GENERATION_INITIATOR",
  WARRANT_GENERATION_REVIEWER: "WARRANT_GENERATION_REVIEWER",
  WARRANT_GENERATION_AUTHORISER: "WARRANT_GENERATION_AUTHORISER",
  EXPENDITURE_CLEARANCE_ENDORSER: "EXPENDITURE_CLEARANCE_ENDORSER",
  EXPENDITURE_CLEARANCE_APPROVER: "EXPENDITURE_CLEARANCE_APPROVER",
};
export const Modules = { GMM: "GMM", EMM: "EMM", WMM: "WMM", DMM: "DMM" };

export const ROLES_OPTIONS = [
  { label: "Super Admin", value: "SUPERADMIN" },
  { label: "Admin", value: "ADMIN" },
  { label: "IT Admin", value: "IT_ADMIN" },
  { label: "MDA Admin", value: "MDA_ADMIN" },
  { label: "MDA Accounting Officer", value: "MDA_ACCOUNTING_OFFICER" },
  { label: "MDA Reviewer", value: "MDA_REVIEWER" },
  { label: "MDA Initiator", value: "MDA_INITIATOR" },
  { label: "MDA Internal Auditor", value: "MDA_INTERNAL_AUDITOR" },
  { label: "MDA Authoriser", value: "MDA_AUTHORISER" },
  { label: "MDA Supervising Officer", value: "MDA_SUPERVISING_OFFICER" },
  { label: "Budget Admin", value: "BUDGET_ADMIN" },
  { label: "EMM Admin", value: "EMM_ADMIN" },
  { label: "Global Auditor", value: "GLOBAL_AUDITOR" },
  { label: "GLO Initiator", value: "GLO_INITIATOR" },
  { label: "Expenditure Manager", value: "DIRECTOR_OF_EXPENDITURE" },
  { label: "Warrant Initiator", value: "WARRANT_INITIATOR" },
  { label: "Warrant Authoriser", value: "WARRANT_AUTHORISER" },
  { label: "Warrant Request Reviewer", value: "WARRANT_REQUEST_REVIEWER" },
  { label: "Warrant Request Authoriser", value: "WARRANT_REQUEST_AUTHORISER" },
  { label: "Warrant Request Checker", value: "WARRANT_REQUEST_CHECKER" },
  { label: "Warrant Request Endorser", value: "WARRANT_REQUEST_ENDORSER" },
  { label: "Warrant Generation Reviewer", value: "WARRANT_GENERATION_REVIEWER" },
  { label: "Warrant Generation Authoriser", value: "WARRANT_GENERATION_AUTHORISER" },
  { label: "Warrant Generation Initiator", value: "WARRANT_GENERATION_INITIATOR" },
  {
    label: "PR Processing Reviewer",
    value: "PAYMENT_REQUEST_PROCESSING_REVIEWER",
  },
  {
    label: "PR Processing Initiator",
    value: "PAYMENT_REQUEST_PROCESSING_INITIATOR",
  },
  {
    label: "PR Processing Authoriser",
    value: "PAYMENT_REQUEST_PROCESSING_AUTHORISER",
  },
  {
    label: "Expenditure Request Reviewer",
    value: "EXPENDITURE_REQUEST_REVIEWER",
  },
  {
    label: "Expenditure Clearance Initiator",
    value: "EXPENDITURE_CLEARANCE_INITIATOR",
  },
  {
    label: "Expenditure Clearance Authoriser",
    value: "EXPENDITURE_CLEARANCE_AUTHORISER",
  },
  { label: "Expenditure Clearance Endorser", value: "EXPENDITURE_CLEARANCE_ENDORSER" },
  { label: "Expenditure digitization Initiator", value: "EXPENDITURE_DIGITIZATION_INITIATOR" },
  { label: "Expenditure digitization Reviewer", value: "EXPENDITURE_DIGITIZATION_REVIEWER" },
  { label: "Expenditure digitization Authoriser", value: "EXPENDITURE_DIGITIZATION_AUTHORISER" },
  { label: "Warrant digitization Initiator", value: "WARRANT_DIGITIZATION_INITIATOR" },
  { label: "Warrant digitization Reviewer", value: "WARRANT_DIGITIZATION_REVIEWER" },
  { label: "Warrant digitization Authoriser", value: "WARRANT_DIGITIZATION_AUTHORISER" },
  { label: "Expenditure Clearance Checker", value: "EXPENDITURE_CLEARANCE_CHECKER" },
  { label: "Expenditure Clearance Approver", value: "EXPENDITURE_CLEARANCE_APPROVER" },
  {
    label: "CPR Processing Checker",
    value: "CONTRACT_PAYMENT_REQUEST_PROCESSING_CHECKER",
  },
  {
    label: "Treasury Cash Officer",
    value: "TREASURY_CASH_OFFICER",
  },
  {
    label: "Funds Manager",
    value: "FUNDS_MANAGER",
  },
  {
    label: "GLO Reviewer",
    value: "GLO_REVIEWER",
  },
  {
    label: "Cash Officer",
    value: "CASH_OFFICER",
  },
  {
    label: "State Executive Expenditure",
    value: "SE_EXPENDITURE",
  },
  {
    label: "State Executive Fund Release",
    value: "SE_FUND_RELEASE",
  },
  {
    label: "State Executive Warrant",
    value: "SE_WARRANT",
  },
  {
    label: "Warrant Request Approver",
    value: "WARRANT_REQUEST_APPROVER",
  },
  {
    label: "Reconciliation Initiator",
    value: "RECONCILIATION_INITIATOR",
  },
  {
    label: "Reconciliation Authoriser",
    value: "RECONCILIATION_AUTHORISER",
  },
];

export const ASIDE_CONTENT = [
  {
    title: "Registration Progress",
    description: "Fill in the fields to complete the user registration",
  },
];
