import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { BehaviorSubject } from "rxjs";
import { getRefreshToken, getToken, isAuthenticated, removeToken, setToken } from "./auth";

let refreshed = false;

export const baseUrl =
  process.env.REACT_APP_API_BASE_URL || "https://api.staging.prowoks.co/api/v1";
export const workbenchSocketUrl =
  process.env.REACT_APP_WORKBENCH_SOCKET_URL ||
  "wss://api.py.prowoks.co/ws/workbench-notification/";

export const subscriber = new BehaviorSubject(0);

const Api = axios.create({
  baseURL: baseUrl,
});

// Request Interceptor
Api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (!config?.headers?.noBearerToken) {
      config.headers = {
        ...config.headers,
        Authorization: isAuthenticated() ? `Bearer ${getToken()}` : "",
      };
    }
    delete config?.headers?.noBearerToken;
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If response is 401 and not already refreshed, try refreshing
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!refreshed) {
        refreshed = true;
        try {
          const res = await Api.post("/auth/token/refresh/", { refresh: getRefreshToken() });

          setToken(res.data.access);

          // Update the Authorization header and retry the failed request
          originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
          refreshed = false;
          return Api(originalRequest);
        } catch (refreshError) {
          refreshed = false;
          handleLogout();
          return Promise.reject(refreshError);
        }
      }
    }

    // If we get an "Invalid Session" error after refresh, log out
    if (error.response?.data?.message === "Invalid Session") {
      handleLogout();
    }

    return Promise.reject(error);
  }
);

// Logout function
const handleLogout = () => {
  toast.error("Session expired. Please log in again.", {
    position: "top-center",
  });
  removeToken();
  setTimeout(() => {
    window.location.href = "/signin"; // Redirect user to login
  }, 1500);
};

// function refreshToken(token: string) {
//   return Api.post("/auth/token/refresh/", {
//     refresh: token,
//   });
// }

export const Post = async (url: string, data: any, config?: any, newUrl?: string) => {
  try {
    return await axios.post(`${newUrl ? newUrl : baseUrl}${url}`, data, {
      responseType: "blob",
      headers: {
        Authorization: isAuthenticated() ? `Bearer ${getToken()}` : "",
        ...config,
      },
    });
  } catch (error: any) {
    subscriber.next(error.response);
    throw error.response;
  }
};

export default Api;
