import React, { ReactNode } from "react";
import { useQuery } from "react-query";
import { Theme, ThemeProvider } from "@mui/material";
import { fetchAppInstance } from "services/appService";
import { createTheme } from "@mui/material/styles";
import Loader from "shared/components/Loader";
import palette from "theme/palette";

interface IAppInstanceProps {
  children: ReactNode;
  theme: Theme;
}

function getFaviconEl() {
  return document.getElementById("favicon") as HTMLAnchorElement | null;
}

function getAppleTouchIcon() {
  return document.getElementById("appleTouchIcon") as HTMLAnchorElement | null;
}

function getappTitle() {
  return document.getElementById("appTitle");
}

const AppInstanceContext = React.createContext<{}>({});

const AppInstanceProvider = ({ children, theme }: IAppInstanceProps) => {
  const domain = window.location.hostname;
  const instanceSlug = process.env.REACT_APP_INSTANCE_DOMAIN
    ? process.env.REACT_APP_INSTANCE_DOMAIN
    : domain;

  const { data, isLoading, isError } = useQuery(
    ["appInstance", { instanceSlug }],
    fetchAppInstance,
    {
      enabled: !!instanceSlug,
      onSuccess: (data) => {
        console.log("Here is the data", data);
        const favicon: HTMLAnchorElement | null = getFaviconEl(); // Accessing favicon element
        const appTouch: HTMLAnchorElement | null = getAppleTouchIcon();
        const appTitle: HTMLElement | null = getappTitle();
        if (favicon) {
          favicon.href = data?.data?.logo;
        }
        if (appTouch) {
          appTouch.href = data?.data?.logo;
        }
        if (appTitle) {
          appTitle.innerHTML = `${data?.data?.name} EMS`;
        }
      },
    }
  );

  const instanceTheme = React.useMemo(() => {
    if (data?.data?.color_variants) {
      return createTheme({
        ...theme,
        palette: {
          primary: {
            main:
              (data?.data?.color_variants && data?.data?.color_variants?.color_2) ||
              palette.primary.main,
            light: data?.data?.color_variants?.color_8 || palette.primary.light,
            dark: data?.data?.color_variants?.color_6 || palette.primary.shade,
          },
          secondary: {
            main:
              (data?.data?.color_variants && data?.data?.color_variants?.color_2) ||
              palette.secondary.main,
            light: data?.data?.color_variants?.color_8 || palette.secondary.light,
            dark: data?.data?.color_variants?.color_6 || palette.secondary.main,
          },
          // @ts-ignore
          tertiary: {
            main:
              (data?.data?.color_variants && data?.data?.color_variants?.color_1) ||
              palette.tertiary.main,
          },
          // @ts-ignore
          background: {
            ...theme?.palette?.background,
            // @ts-ignore
            avatar: data?.data?.color_variants?.color_2,
          },
        },
        components: {
          ...theme.components,
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 4,
                textTransform: "capitalize",
                color: data?.data?.color_variants?.color_8,
                "&:hover": {
                  // border: "none",
                  background: data?.data?.color_variants?.color_1,
                  color: data?.data?.color_variants?.color_8,
                },
              },
              outlinedPrimary: {
                border: "0px",
                color: data?.data?.color_variants?.color_2,
                background: data?.data?.color_variants?.color_8,
                "&:hover": {
                  border: "none",
                  background: data?.data?.color_variants?.color_7,
                  color: data?.data?.color_variants?.color_2,
                },
              },
              outlinedSecondary: {
                border: "0px",
                color: (data?.data?.color_variants && data?.data?.color_variants?.color_2) || "",
                background: data?.data?.color_variants?.color_8,
                // lighten(data?.data?.secondary_color, 0.9),
                "&:hover": {
                  border: "none",
                  background: data?.data?.color_variants?.color_1,
                  // lighten(data?.data?.secondary_color, 0.7),
                },
              },
            },
          },
          // MuiTextField: {
          //   styleOverrides: {
          //     root: {
          //       "& label": {
          //         // opacity: ".5",
          //         color: "#ccc",
          //       },
          //       // Default state of text input field font.
          //       "& .MuiInputBase-input.MuiInput-input": {
          //         fontFamily: "Manrope",
          //         color: "#2A2B2F",
          //       },

          //       // Default state of underline.
          //       "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before":
          //         {
          //           borderBottomColor: 'green',
          //         },

          //       // On hover state of underline.
          //       "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before":
          //         {
          //           borderBottomColor: "blue",
          //         },

          //       // On focus state of underline.
          //       "& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after":
          //         {
          //           borderBottom: "2px solid #2E98EF",
          //         },
          //       // Default state of label.
          //       "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard":
          //         {
          //           color: "green",
          //         },

          //       // On focus state of label (after text is entered into the form field).
          //       "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard":
          //         {
          //           color: "yellow",
          //         },
          //     },
          //   },
          // },
        },
      });
    }
    return theme;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  if (isError) return <p>Error: Failed to get instance slug. </p>;

  if (isLoading) return <Loader />;

  return (
    <AppInstanceContext.Provider value={data?.data}>
      <ThemeProvider theme={instanceTheme}>{children}</ThemeProvider>
    </AppInstanceContext.Provider>
  );
};

export const useAppInstance: any = () => {
  return React.useContext(AppInstanceContext);
};

export default AppInstanceProvider;
