import Api from "utils/api";

export const fetchAppInstance = async ({ queryKey }: any) => {
  const [, { instanceSlug }] = queryKey;
  return Api.get(`/accounts/slug/${instanceSlug}/`, {
    headers: {
      noBearerToken: true,
    },
  }).then((res) => res.data);
};

export const activateBudgetOverspend = async ({ payload }: any) => {
  console.log(payload);
  return Api.post(`/accounts/activate-budget-overspend/`, payload).then((res) => res.data);
};
export const deactivateBudgetOverspend = async ({ payload }: any) => {
  return Api.post(`accounts/deactivate-budget-overspend/`, payload).then((res) => res.data);
};
